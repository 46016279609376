import React from "react";
// import styled from "styled-components";

// import { graphql, useStaticQuery } from "gatsby";
// import { getImage, GatsbyImage } from "gatsby-plugin-image";
// import BackgroundImage from "gatsby-background-image";

import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const Layout = ({ children }) => {
  // const { hes_radient } = useStaticQuery(
  //   graphql`
  //     query {
  //       hes_radient: file(relativePath: { eq: "cover.jpeg" }) {
  //         childImageSharp {
  //           fluid(quality: 100, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_withWebp
  //           }
  //         }
  //       }
  //     }
  //   `
  // );

  return (
    <div className="layout">
      <GlobalStyles />
      <Nav />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
