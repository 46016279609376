import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { Twirl as Hamburger } from "hamburger-react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import "animate.css";

const Nav = () => {
  const { hsLogo } = useStaticQuery(
    graphql`
      query {
        hsLogo: file(relativePath: { eq: "hs.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 46
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const [isToggled, setIsToggled] = useState(false);
  const hide = () => setIsToggled(false);
  const image = getImage(hsLogo);

  return (
    <NavCss className="nav">
      <Link to="/">
        <div className="nav_logo">
          <GatsbyImage className="hs_logo" image={image} alt={"testimage"} />
        </div>
      </Link>
      <div className="navbar_right">
        <ul className="navbar_right">
          <li>
            <Link to="/">HES</Link>
          </li>
          <li>
            <Link to="/events">Events</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <div className="hamburger">
        <Hamburger
          duration={0.6}
          toggled={isToggled}
          toggle={setIsToggled}
          size={27}
          color="#fff"
        />
      </div>
      {isToggled && (
        <div className="collapse_navbar">
          <Link to="/">
            <h1 onClick={hide}>HES</h1>
          </Link>
          <Link to="/events">
            <h1 onClick={hide}>Events</h1>
          </Link>
          <Link to="/contact">
            <h1 onClick={hide}>Contact</h1>
          </Link>
        </div>
      )}
    </NavCss>
  );
};

const NavCss = styled.nav`
  display: flex;
  height: 60px;
  padding: 3px 12px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 111;
  transition: background 0.3s ease-out;
  background: rgba(0, 0, 0, 0.02);

  ul {
    display: flex;
    list-style: none;
    overflow: hidden;
  }

  li {
    margin: 0 19px;
  }

  a {
    text-decoration: none;
    color: var(--offwhite);
    font-weight: 300;
    font-size: 1.8rem;
  }
  :hover a {
    text-decoration: none;
  }
  .nav_logo {
    /* background: pink; */
    position: relative;
    /* width: 220px; */
    background-size: contain;
    background-repeat: no-repeat !important;
    margin-left: 10px;
  }

  .hamburger {
    display: none;
  }
  .collapse_navbar {
    display: none;
  }

  @media screen and (max-width: 855px) {
    .navbar_middle li {
      display: none;
    }
    .navbar_right li {
      display: none;
    }
    .login_icon {
      display: none;
    }
    .hamburger {
      display: block;
      margin-right: 10px;
    }
    .collapse_navbar {
      display: flex;
      position: fixed;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      height: calc(100vh - 60px);
      top: 60px;
      right: 0;
      padding: 21px 34px 160px;
      background: rgba(37, 37, 37, 0.99);
      color: var(--offwhite);
      z-index: 2222;
      animation: fadeIn;
      animation-duration: 0.3s;
    }
    .collapse_navbar h1 {
      font-size: 2.6rem;
      font-weight: 300;
      color: var(--offwhite);
    }
    .collapse_navbar .login_icon {
      margin-bottom: 5px;
      margin-left: 10px;
      display: block;
    }
  }
`;

export default Nav;
