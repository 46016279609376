// import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const Footer = () => (
  <FooterCss className="footer_css">
    <div className="left">©2023 HES Sound</div>
    <div className="right">
      {/* <ul>
        <li>
          <Link to="">Regulamin</Link>
        </li>
        <li>
          <Link to="">Polityka prywatności</Link>
        </li>
        <li>
          <Link to="">Płatności</Link>
        </li>
      </ul> */}
    </div>
  </FooterCss>
);

const FooterCss = styled.footer`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.03);
  /* height: 120px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-family: Lato;
  z-index: 111;
  overflow: hidden;
  color: var(--offwhite);
  font-size: 1.2rem;
  padding: 9px;

  ul {
    display: inline;
    list-style: none;
    overflow: hidden;
  }

  li {
    padding: 1px;
  }

  a {
    text-decoration: none;
    color: var(--offwhite);
    font-weight: 400;
  }
  .left {
    text-align: left;
    margin-right: 20px;
    color: var(--gray);
  }
  .right {
    text-align: right;
    font-size: 1.6rem;
    color: var(--offwhite);
  }
  .footer_logo {
    background: pink;
    position: relative;
    width: 165px;
    background-size: cover;
    background-repeat: no-repeat !important;
    height: 30px;
    bottom: 5px;
  }
  @media screen and (max-width: 510px) {
    display: none;
  }
  @media screen and (max-height: 692px) {
    display: none;
  }
`;

export default Footer;
